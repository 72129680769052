import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'SETTINGS';
export const NF_SETTING_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.DOC,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }

        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.HOS,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.HOT,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.CAF,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.EDU,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.SPA,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.SAL,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.RTL,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
  {
    application: NfApplication.Setting,
    appExpCode: NfAppExpCode.SVC,
    menu: {
      name: menuName,
      items: [
        {
          name: 'My Account',
          url: '/setting/my-account',
           meta: {
             boostTitle: 'HealthGro: My Account',
             jioTitle: 'JioOnline: My Account'
           }
        },
        // {
        //   name: 'My Bank Account',
        //   url: '/setting/my-bank-account',
        //    meta: {
        //      boostTitle: 'HealthGro: My Bank Account',
        //      jioTitle: 'JioOnline: My Bank Account'
        //    }
        // },
        {
          name: 'My Digital Channels',
          url: 'manage-content/content-sharing-setting',
           meta: {
             boostTitle: 'HealthGro: Third Party Integration',
             jioTitle: 'JioOnline: Third Party Integration'
           }
        },
        {
          name: 'Renewal History',
          url: '/setting/renewal-history',
           meta: {
             boostTitle: 'HealthGro: Your Plan',
             jioTitle: 'JioOnline: Your Plan'
           }
        },
        {
          name: 'Domain & Email',
          url: '/setting/domain-and-email',
           meta: {
             boostTitle: 'HealthGro: Domain Details',
             jioTitle: 'JioOnline: Domain Details'
           }
        },
        {
          name: 'Logout',
          url: '/setting/logout',
           meta: {
             boostTitle: 'HealthGro: Logout',
             jioTitle: 'JioOnline: Logout'
           }
        },
      ],
    },
  },
];
