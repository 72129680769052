<div class="addons-detail-modal-wrapper">
  <div class="addons-detail-header">
    <i class="modal-backicon">
      <!-- <img src="assets/images/modal-back-icon.svg"> -->
    </i>
    <!-- Invite your friends to HealthGro! -->
    <span class="modal-close" (click)="dialogRef.close()">
      <img src="assets/images/modal-close-icon.svg" />
    </span>
  </div>
  <div class="iframeWrapper">
    <iframe
      (load)="myLoadEvent()"
      [src]="linkUrl"
      width="100%"
      frameborder="0"
      height="600px"
    >
    </iframe>
    <!--
    <div class="loader" *ngIf="isLoader">
      <img src="../../../assets/images/marketplaceLaoder.gif" />
    </div> -->
  </div>
</div>

<div class="lottie" *ngIf="isLoader">
  <ng-lottie width="200px" height="200px" [options]="options"></ng-lottie>
  <div>Loading marketplace...</div>
</div>
