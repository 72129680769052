import { NfAppExpCode, NfApplication } from '../menu.enum';
import { NfMenuConfig } from '../menu.type';

const menuName = 'CUSTOMERS';
const titleName = 'HealthGro: Online Call Tracking Dashboard';
export const NF_MANAGE_USER_MENU_CONFIG: NfMenuConfig[] = [
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.DOC,
    menu: {
      name: 'PATIENTS',
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'In-Clinic Appointments',
          meta: {
            boostTitle: 'HealthGro: Manage your bookings',
            jioTitle: 'JioOnline: Manage your bookings'
          },
          url: '/manage-user/in-clinic-appointments',
        },
        {
          name: 'Video Consultations',
          meta: {
            boostTitle: 'HealthGro: Healthcare Video Consultation',
            jioTitle: 'JioOnline: Healthcare Video Consultation'
          },
          url: '/manage-user/video-consultations',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.HOS,
    menu: {
      name: 'PATIENTS',
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'In-Clinic Appointments',
          meta: {
            boostTitle: 'HealthGro: Manage your bookings',
            jioTitle: 'JioOnline: Manage your bookings'
          },
          url: '/manage-user/in-clinic-appointments',
        },
        {
          name: 'Video Consultations',
          meta: {
            boostTitle: 'HealthGro: Healthcare Video Consultation',
            jioTitle: 'JioOnline: Healthcare Video Consultation'
          },
          url: '/manage-user/video-consultations',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.HOT,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.CAF,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'Orders',
          meta: {
            boostTitle: 'HealthGro: Manage your orders',
            jioTitle: 'JioOnline: Manage your orders'
          },
          url: '/manage-user/orders',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.EDU,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'Orders',
          meta: {
            boostTitle: 'HealthGro: Manage your orders',
            jioTitle: 'JioOnline: Manage your orders'
          },
          url: '/manage-user/orders',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.SPA,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'Orders',
          meta: {
            boostTitle: 'HealthGro: Manage your orders',
            jioTitle: 'JioOnline: Manage your orders'
          },
          url: '/manage-user/orders',
        },
        {
          name: 'Manage Appointments',
          meta: {
            boostTitle: 'HealthGro: Manage your bookings',
            jioTitle: 'JioOnline: Manage your bookings'
          },
          url: '/manage-user/manage-appointments',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.SAL,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'Orders',
          meta: {
            boostTitle: 'HealthGro: Manage your orders',
            jioTitle: 'JioOnline: Manage your orders'
          },
          url: '/manage-user/orders',
        },
        {
          name: 'Manage Appointments',
          meta: {
            boostTitle: 'HealthGro: Manage your bookings',
            jioTitle: 'JioOnline: Manage your bookings'
          },
          url: '/manage-user/manage-appointments',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.MFG,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'Orders',
          meta: {
            boostTitle: 'HealthGro: Manage your orders',
            jioTitle: 'JioOnline: Manage your orders'
          },
          url: '/manage-user/orders',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.RTL,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'Orders',
          meta: {
            boostTitle: 'HealthGro: Manage your orders',
            jioTitle: 'JioOnline: Manage your orders'
          },
          url: '/manage-user/orders',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
  {
    application: NfApplication.ManageUser,
    appExpCode: NfAppExpCode.SVC,
    menu: {
      name: menuName,
      title: titleName,
      items: [
        {
          name: 'Calls',
          meta: {
            boostTitle: 'HealthGro: Online Call Tracking Dashboard',
            jioTitle: 'JioOnline: Online Call Tracking Dashboard'
          },
          url: '/manage-user/calls',
        },
        {
          name: 'Enquiries',
          meta: {
            boostTitle: 'HealthGro: Business Enquires',
            jioTitle: 'JioOnline: Business Enquires'
          },
          url: '/manage-user/enquiries',
        },
        {
          name: 'Orders',
          meta: {
            boostTitle: 'HealthGro: Manage your orders',
            jioTitle: 'JioOnline: Manage your orders'
          },
          url: '/manage-user/orders',
        },
        {
          name: 'Appointments',
          meta: {
            boostTitle: 'HealthGro: Manage your bookings',
            jioTitle: 'JioOnline: Manage your bookings'
          },
          // url: '/manage-user/appointments',
          url: '/manage-user/in-clinic-appointments',
        },
        {
          name: 'Newsletter Subscriptions',
          meta: {
            boostTitle: 'HealthGro: Your Local Customer - Subscribed to your business updates',
            jioTitle: 'JioOnline: Your Local Customer - Subscribed to your business updates'
          },
          url: '/manage-user/newsletter-subscriptions',
        },
      ],
    },
  },
];
